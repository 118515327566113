
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import UploadFile from '@/components/uploadFile/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { PointDetail } from '@/types/patrol'
import { FileInfo } from '@/types/common'

@Component({
  name: 'PointAddPage',
  components: { MapLocation, UploadFile }
})
export default class extends Vue {
  private typeList = []
  private disabled = false

  private formData: PointDetail = {
    facilityName: '',
    facilityTypeId: '',
    projectId: '',
    address: '',
    longitude: '',
    latitude: '',
    fileList: [],
    resourcesLocationList: []
  }

  private rules = {
    facilityName: [
      { required: true, message: '请输入名称', trigger: ['blur', 'change'] }
    ],
    facilityTypeId: [
      { required: true, message: '请择类型', trigger: ['blur', 'change'] }
    ],
    projectId: [
      { required: true, message: '请选择所属项目', trigger: ['blur', 'change'] }
    ],
    resourcesLocationList: [
      { required: true, message: '请输入定位', trigger: ['blur', 'change'] }
    ]
  }

  private resourcesLocationList: Array<{ longitude: number; latitude: number }> | null= []

  get projectList () {
    return this.$store.state.projectList || []
  }

  get facilityId () {
    return this.$route.params.id || ''
  }

  created (): void {
    this.getTypeList()
    if (this.facilityId) {
      this.loadData()
    }
  }

  locationChange (val: Array<{ longitude: number; latitude: number }>) {
    this.formData.resourcesLocationList = val
  }

  getTypeList () {
    this.$axios.get(this.$apis.patrol.selectFacilityTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  // 查看设备设施详情
  loadData () {
    return this.$axios.get(this.$apis.patrol.selectFacilityByFacilityId, {
      facilityId: this.facilityId
    }).then(res => {
      this.formData = res || {}
      setTimeout(() => {
        this.resourcesLocationList = this.formData.resourcesLocationList = [{ longitude: +this.formData.longitude, latitude: +this.formData.latitude }] || []
      }, 3000)
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.formData.fileList = [file]
  }

  imgRemove () {
    this.formData.fileList = []
  }

  onSubmit () {
    (this.$refs.form as ElForm).validate().then(() => {
      this.disabled = true
      const url = this.facilityId ? this.$apis.patrol.updateFacility : this.$apis.patrol.insertFacility
      const { longitude, latitude } = this.formData.resourcesLocationList[0]
      this.formData.longitude = longitude.toString()
      this.formData.latitude = latitude.toString()
      const info = JSON.parse(JSON.stringify(this.formData))
      delete info.resourcesLocationList
      this.$axios.post(url, info).then(() => {
        this.$router.push('/patrol/patrolPoint')
      }).finally(() => {
        this.disabled = false
      })
    })
  }
}
